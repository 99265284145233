import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { IframeMain } from "../styles/iframe.styles"
import { SectionTitle } from "../elements/section-title"

import BHVideosModal from "../components/bh-videos-modal"
import useModalProps from "../helpers/use-modal-props"
import "../styles/trader-examples/trader-example.style.css"

const TraderExamplePage = (
    {
        data: {
          wpgraphql: {
            page: {
              title,
              traderexampleacf,
              digitaldisclaimersacf
            }
          },
        }
      }
) => {

  const { modalProps } = useModalProps({ initialOpen: typeof window !== 'undefined' ? (window.localStorage.getItem('bh-disclosureNoticesStatus') !== 'accepted' ? false : true) : false });
  const { modalStatus } = modalProps

    return (

        <Layout isableAgreementModal={true} onAccept={() => {
          modalProps?.openModal({})
        }} modalStateOverride={!modalStatus?.open}>
            
            <SEO title={title} />

            <IframeMain>
                <div className="iframe-container vif-pd trader-content-wrapper">
  
                    <div>
                        <SectionTitle variation="title-container" text={traderexampleacf.pageHeading} responsive={[{ breakpoint: 767, charLimit: 6 }]} />
                    </div>

                    <iframe src={traderexampleacf.pageVideo} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

                    <div className="trader-content-container" dangerouslySetInnerHTML={{__html: traderexampleacf.pageContent}}></div>
                </div>
                
            </IframeMain>

          <BHVideosModal
            modalProps={modalProps}
            digitalassetsacf={digitaldisclaimersacf}
          />            

        </Layout>
    )
}

export default TraderExamplePage;

export const tradeExampleQuery = graphql`
  query TradeExampleQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {   
        title 
        traderexampleacf {
          pageVideo
          pageHeading
          pageContent
          fieldGroupName
        }
        digitaldisclaimersacf {
          modalFooterText
          modalCloseButtonLabel
          modalAcceptButtonLabel
          disclosuresModalData {
            title
            content {
              text
              title
            }
          }
          riskFactorsModalData {
            title
            content {
              text
              title
            }
          }
        }
      }
    }
  }
`